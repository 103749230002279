import dynamic from "next/dynamic";
import React from "react";
import useWindowSize from "../../_hooks/useWindowSize";

const HeaderTopSection = dynamic(() => import("./HeaderTopSection"));
const HeaderMiddleSection = dynamic(() => import("./HeaderMiddle/HeaderMiddleSection"));
const HeaderCategories = dynamic(() => import("./Categories/HeaderCategories"));
const BoxContainer = dynamic(() => import("../Reusables/BoxContainerV2"));

const Header2 = () => {
    const {width, isMobile} = useWindowSize();
    return (
        <>
            { !isMobile || width >  1000 ? <HeaderTopSection/> : null }
            <BoxContainer>
            { !isMobile || width >  1000 ? <HeaderMiddleSection/>: null }
            </BoxContainer>
            {/* { !isMobile || width >  1000 ? <HeaderBar3/> :  null} */}
            { !isMobile || width >  1000 ? <HeaderCategories/> :  null}
        </>
    )
}

export default React.memo(Header2)